@font-face {
    font-family: Bandein Sans Web;
    src: url("./fonts/BandeinsSans-Light.woff2") format("woff2");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: Bandein Sans Web;
    src: url("./fonts/BandeinsSans-Regular.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: Bandein Sans Web;
    src: url("./fonts/BandeinsSans-Medium.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: Bandein Sans Web;
    src: url("./fonts/BandeinsSans-SemiBold.woff2") format("woff2");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: Bandein Sans Web;
    src: url("./fonts/BandeinsSans-Bold.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: Bandein Sans Strange Variable;
    src: url("./fonts/BandeinsStrangeVariable.ttf") format("truetype-variations")
}

