.contact-page {
  position: relative;
  z-index: 5;
  overflow: hidden;
  color: #fff;
  display: flex;
  justify-content: center;
}

.contact-content {
  /* display: flex;
  flex-direction: column; */
  /* justify-content: center; */
  position: relative;
  width: 70vw;
  left: -5vw;
  margin-top: 15vh;
}

.contact-text h4 {
  font-size: clamp(1rem, 1vw + 2rem, 3rem);
}

.contact-text a {
  font-size: clamp(1rem, 1vw + 1rem, 2rem);
}




.email-link {
  margin-left: 40px;
  color: white;

}

@media (max-width: 600px) {
  .contact-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: 0;
    margin-top: 4rem;
    height: fit-content;
    left: 0;
  }

  .contact-text {
    margin-left: 0;
    margin: 1rem;
  }

  .contact-text h4 {
    margin: 1rem 0;
  }

}
