.side-menu {
  position: fixed;
  text-align: right;
  text-decoration: none;
  text-transform: uppercase;
  font-family: "Bandein Sans Strange Variable", sans-serif;
  font-variation-settings: "wdth" 400;
  font-weight: 500;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  height: 100%;
  font-size: clamp(1em, 1vw + 1.2em, 2em);
  margin-right: 2%;
  z-index: 995;
  color: #fff;
}

.menu-fullscreen {
  position: fixed;
  text-decoration: none;
  text-transform: uppercase;
  font-family: "Bandein Sans Strange Variable", sans-serif;
  font-variation-settings: "wdth" 400;
  font-weight: 500;
  font-size: clamp(1rem, 1vw + 3rem, 3rem);
  justify-content: end;
  align-items: center;
  text-align: right;
  width: 100%;
  height: 100%;
  z-index: 990;
}

.menu-fullscreen-bg {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 60vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  min-width: 600px;
  backdrop-filter: blur(50px);
  
}

.side-menu-item-fullscreen {
  margin-right: 2rem;
}

.blur-background {
  z-index: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  /* background: rgba(0, 0, 0, 0.2); */
  /* backdrop-filter: blur(50px); */
}

.menu-btn {
  position: fixed;
  display: none;
  top: 2vh;
  right: 2vh;
  z-index: 999;
  width: 52px;
  height: 52px;
}

.svg-btn {
  fill: #fff;
  fill-opacity: 0;
  stroke: #fff;
  stroke-width: 2px;
}

.menu-btn:hover {
  cursor: pointer;
}

@media (max-width: 1000px) {
  .menu-btn {
    display: block;
  }

  .side-menu {
    display: none;
  }
}
