.work-page {
  z-index: 5;
  width: 80%;
  color: #fff;
  padding-top: 3%;
  padding-left: 5vw;
}

.work-sections {
  display: flex;
}
.work-left {
  max-width: 30rem;
  width: 35%;
  z-index: 2;
}

.work-left ul {
  padding: 0;
}

.work-left h3 {
  text-transform: uppercase;
  font-family: "Bandein Sans Strange Variable", sans-serif;
  font-variation-settings: "wdth" 400;
  font-weight: 600;
  font-size: clamp(1rem, 1vw + 0.7rem, 2rem);
}

.project {
  width: 65%;
  max-width: 1200px;
  background-color: #0000001f;
  backdrop-filter: blur(50px);
  padding: 1rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.project img {
  width: 100%;
  height: 50vh;
  object-fit: cover;
  border-radius: 0.5rem;
}

.project-info {
  display: flex;
  justify-content: space-between;
}

.project-title hr {
  border: #fff solid 1px;
}

.project-description {
  width: 70%;
  font-size: clamp(0.5rem, 1vw + 0.5rem, 1.5rem);
}

.project-category {
  font-family: "Bandein Sans Strange Variable", sans-serif;
  padding-bottom: 1rem;
}
@media (max-width: 768px) {
  .work-left {
    width: 80%;
  }
  .work-left h3 {
    font-size: clamp(1rem, 2vw + 1rem, 3rem);
  }
  .work-sections {
    flex-direction: column;
  }

  .project {
    width: 100%;
    padding-bottom: 1rem;
  }
}
