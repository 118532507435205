body {
  background-color: #010847;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Bandein Sans Web, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  list-style-type: none;
}

.page-title {
  z-index: 2;
  width: 70%;
}

.page-title h2 {
  text-transform: uppercase;
  font-family: "Bandein Sans Strange Variable", sans-serif;
  font-variation-settings: "wdth" 400;
  font-weight: 600;
  font-size: clamp(3rem, 2vw + 3rem, 6rem);
  margin: 0;
  color: transparent;
  -webkit-text-stroke: 2px white;
}

a:link {
  text-decoration: none;
  color: inherit;
}
a:visited {
  text-decoration: none;
  color: inherit;
}
a:hover {
  text-decoration: none;
}
a:active {
  text-decoration: none;
}
