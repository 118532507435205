.home-page {
  position: absolute;
  color: #fff;
  font-family: "Bandein Sans Web", sans-serif;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.home-page_content {
  display: flex;
  align-items: center;
  text-transform: uppercase;

  justify-content: center;
  position: relative;
}

.home-page_text {
  text-align: right;
  letter-spacing: clamp(0.02em, 0.01em + 0.3vw, 1em);
  padding-right: 2rem;
  font-size: clamp(0.2em, 2vw + 0.5em, 1em);
  overflow-x: hidden;
  position: absolute;
  right: 0;
  white-space: nowrap;
}

.home-nav {
  overflow-x: hidden;
  position: absolute;
  left: 0;
  width: max-content;
  font-size: clamp(1em, 2vw + 2em, 4em);
  font-family: "Bandein Sans Strange Variable", sans-serif;
  font-variation-settings: "wdth" 400;
  font-weight: 500;
  letter-spacing: 0.2em;
}

.home-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.home-menu_item {
  margin-left: 2rem;
}

.middle-line {
  border: 1px solid #fff;
  height: 14rem;
}

.home-socials {
  position: absolute;
  left: 25%;
  bottom: 20%;
}

.home-socials_item {
  clip-path: inset(0 0 -1px 0);
}

.home-socials ul {
  list-style: none;
  padding: 0;
}

@media (max-width: 768px) {
  .home-page_content {
    flex-direction: column;
  }
  .middle-line {
    height: 0;
    width: 80vw;
  }
  .home-page_text {
    text-align: left;
    padding: 0;
    flex-basis: 0;
    width: 80vw;
    bottom: 10%;
    clip-path: none;
    margin-bottom: 1rem;
    overflow: visible;
  }
  .home-nav {
    margin-top: 2rem;
    clip-path: none;
    flex-basis: 0;
    width: 80vw;
    text-align: right;
    overflow: visible;
    top: 10%;
  }
  .home-socials {
    left: 15vw;
    bottom: 5vh;
  }
  .home-menu_item {
    margin-left: 0;
  }
}
